import axios from 'axios'

/**
 * Получение превью страницы
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_DECREASE_ALIMENT_PREVIEW_PAGE ({ state, commit }) {
  const { data } = await axios.get('/api/previews/alimony_decrease')
  commit('decrease_aliment_preview_page', data)
}

export {
  GET_DECREASE_ALIMENT_PREVIEW_PAGE
}
