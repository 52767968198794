export default {
  home_page (state, data) {
    state.home_page = data
  },
  confirmed_claim (state, data) {
    state.confirmed_claim = data
  },
  user (state, data) {
    state.user = data
  },
  agree (state, data) {
    state.agree = data
  }
}
