<template>
  <div>
    <span>{{ title }}</span>
  <template v-for="_ in dots">.</template>
  </div>
</template>

<script>

/**
 * Текст "Подождите" с динамическими точками
 */
export default {
  name: 'LoadingIndicator',
  props: {
    title: {
      type: String,
      default: 'Подождите, идет загрузка'
    }
  },
  data () {
    return {
      dots: 1,
      interval: null
    }
  },
  created () {
    this.interval = setInterval(() => {
      if (this.dots >= 5) {
        this.dots = 1
      } else {
        this.dots += 1
      }
    }, 200)
  }
}
</script>
