<template>
  <div class="input-wrap">
    <input
      class="input"
      @input="onInput"
      :class="classes"
      :type="type"
      :required="$attrs.required"
      :title="$attrs.title"
      :autofocus="$attrs.autofocus"
      :disabled="$attrs.disabled"
      :maxlength="$attrs.maxlength"
      :inputmode="$attrs.inputmode"
      :min="$attrs.min"
      :step="$attrs.step"
      :value="value"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
    <label class="label">{{ $attrs.placeholder }}</label>
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'InputField',
  inheritAttrs: false,
  props: {
    classes: {
      type: String,
      default: null
    },
    value: {
      type: [String, Number]
    },
    type: {
      type: String,
      default: 'text'
    }
  },
  methods: {
    onInput (event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>

<style lang="scss">
.input-wrap {
  position: relative;
  width: 100%;
}

.label {
  color: #8B8B8B;
  font-size: 16px;
  line-height: 1.375;
  position: absolute;
  pointer-events: none;
  left: 21px;
  top: 24px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 40px);
}

.input {
  width: 100%;
  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  position: relative;
  box-sizing: border-box;
  transition: 0.2s;
  text-indent: 1px;
  color: #000000;
  border: 1px solid #e5e5e5;
  background: #ffffff;
  border-radius: 10px;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 34px 20px 14px 20px;
}

.input:focus + .label, .input:valid + .label, .input[type="email"] + .label, .input[type="number"] + .label {
  top: 15px;
  font-size: 12px;
  line-height: 1.25;
}
</style>
