import axios from 'axios'

/**
 * Получение данных главной страницы
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_HOME_PAGE ({ state, commit }) {
  const { data } = await axios.get('/api/homepage')
  commit('home_page', data)
}

export {
  GET_HOME_PAGE
}
