import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default function createRouter () {
  const routes = [
    {
      path: '/home',
      name: 'home',
      component: () => import('./views/home/index.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('./views/about/index.vue')
    },
    {
      path: '/support',
      name: 'support',
      component: () => import('./views/support/index.vue')
    },
    {
      path: '/divorce',
      name: 'divorce',
      component: () => import('./views/bots/divorce/index.vue')
    },
    {
      path: '/divorce/start',
      name: 'divorce-start',
      component: () => import('./views/bots/divorce/start.vue')
    },
    {
      path: '/aliment/start',
      name: 'aliment-start',
      component: () => import('./views/bots/aliment/start.vue')
    },
    {
      path: '/aliment',
      name: 'aliment',
      component: () => import('./views/bots/aliment/index.vue')
    },
    {
      path: '/cancel',
      name: 'cancel',
      component: () => import('./views/bots/cancel/index.vue')
    },
    {
      path: '/cancel/start',
      name: 'cancel-start',
      component: () => import('./views/bots/cancel/start.vue')
    },
    {
      path: '/traffic/start',
      name: 'traffic-start',
      component: () => import('./views/bots/traffic/start.vue')
    },
    {
      path: '/decrease-aliment/start',
      name: 'decrease-aliment-start',
      component: () => import('./views/bots/decrease-aliment/start.vue')
    },
    {
      path: '/increase-aliment/start',
      name: 'increase-aliment-start',
      component: () => import('./views/bots/increase-aliment/start.vue')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('./views/news/list.vue')
    },
    {
      path: '/news/:id',
      name: 'article',
      component: () => import('./views/news/item.vue')
    },
    {
      path: '/agreement',
      name: 'agreement',
      component: () => import('./views/law/agreement.vue')
    },
    {
      path: '/confidentiality',
      name: 'confidentiality',
      component: () => import('./views/law/confidentiality.vue')
    },
    {
      path: '/confirmedpay/:id',
      name: 'confirmedpay',
      component: () => import('./views/bots/pay.vue')
    },
    {
      path: '/account',
      name: 'account',
      component: () => import('./views/account/index.vue')
    },
    {
      path: '/resetpassword/:token',
      name: 'resetpassword',
      component: () => import('./views/account/reset.vue')
    },
    {
      path: '/notready',
      name: 'not-ready',
      component: () => import('./views/PageNotReady.vue')
    },
    {
      path: '*',
      name: 'notfound',
      component: () => import('./views/PageNotFound.vue')
    }
  ]

  routes.forEach((item) => {
    const { meta } = item
    let { path } = item
    let baseDir = '/'
    if (meta && meta.project) {
      baseDir = `/${meta.project}`
    }

    if (path === '/home') {
      path = `${baseDir}/`
    } else if (path === '/' && item.redirect) {
      item.redirect = `${baseDir}/`
    } else {
      path = `${path}`
    }

    item.path = path
  })

  return new Router({
    mode: 'history',
    scrollBehavior: function (to, from, savedPosition) {
      if (to.hash !== '') {
        return { selector: to.hash }
      } else {
        return { x: 0, y: 0 }
      }
    },
    fallback: true,
    routes
  })
}
