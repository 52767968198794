<template>
  <header class="header">
    <div class="header__container">
      <nav class="header__navigation" aria-label="Навигация">
        <router-link :to="{ name: 'home' }" class="header__logo" aria-label="SudBot" title="SudBot">
          <img src="/public/img/logo.svg" alt="SudBot"/>
          <span>Судбот</span>
        </router-link>
        <ul class="header__menu">
          <li :aria-label="link.title" v-for="(link, index) in menuLinks" :key="index">
            <router-link :to="{ name: link.name }">{{ link.title }}</router-link>
          </li>
        </ul>
      </nav>
      <mq-layout mq="lg" class="header__form">
        <a href="tel:+79882727273" class="header__phone">+7 988 272 72 73</a>
        <button @click="toggleLogin" class="header__login" type="button" title="Личный кабинет">
          <LogoutIcon v-if="$store.state.cookies.access_token && $route.name === 'account'"/>
          <LoginIcon v-else/>
        </button>
      </mq-layout>
      <mq-layout mq="700" class="header__form">
        <button class="header__login" type="button" title="Меню" @click="toggleMenu">
          <MenuIcon/>
        </button>
      </mq-layout>
    </div>
    <div class="modal modal__login" v-if="loginModal">
      <div class="modal__background" @click="toggleLogin"></div>
      <form class="modal__body" :class="{ shake: showError }" @submit.prevent="submitForm">
        <a href="#" class="modal__close" @click.prevent="toggleLogin">
          <CloseIcon/>
        </a>
        <span class="modal__login__title">{{ forgotPassword ? 'Восстановление пароля' : 'Вход' }}</span>
        <InputField
          :required="true"
          :type="'email'"
          :classes="'mb-12'"
          :title="'Логин'"
          :placeholder="'E-mail'"
          v-model="form.email"
        />
        <InputField
          v-if="!forgotPassword"
          :type="'password'"
          :required="true"
          :classes="'mb-12'"
          :title="'Пароль'"
          :placeholder="'Пароль'"
          v-model="form.password"
        />
        <span class="modal__login__error" v-if="showError">Неверное имя пользователя или пароль.</span>
        <div class="modal__login__row">
          <button class="modal__login__button" type="submit">
            <LoadingIndicator title="Ждите" v-if="loading"/>
            <template v-else>{{ forgotPassword ? 'Напомнить' : 'Войти' }}</template>
          </button>
          <a href="#" v-if="!loading" @click.prevent="forgotPassword = !forgotPassword" class="modal__login__forgot">
            {{ forgotPassword ? 'Хотите войти?' : 'Забыли пароль?' }}
          </a>
        </div>
      </form>
    </div>
    <div class="modal modal__login" v-if="menuModal">
      <div class="modal__background" @click="toggleMenu"></div>
      <div class="modal__body">
        <a href="#" class="modal__close" @click.prevent="toggleMenu">
          <CloseIcon/>
        </a>
        <ul class="modal__menu">
          <li :aria-label="link.title" v-for="(link, index) in menuLinks" :key="index">
            <router-link :to="{ name: link.name }">{{ link.title }}</router-link>
          </li>
          <li aria-label="Личный кабинет">
            <a
              :href="'/account'"
              v-if="$store.state.cookies.access_token && $route.name === 'account'"
              @click.prevent="toggleLogin"
            >
              Выйти
            </a>
            <a :href="'/account'" v-else @click.prevent="toggleLogin">Личный кабинет</a>
          </li>
          <li>
            <a href="tel:+79882727273" class="header__phone">+7 988 272 72 73</a>
          </li>
        </ul>
      </div>
    </div>
  </header>
</template>

<script>
import moment from 'moment'
import LoadingIndicator from './LoadingIndicator.vue'
import LoginIcon from './svg/Login.vue'
import LogoutIcon from './svg/Logout.vue'
import CloseIcon from './svg/Close.vue'
import MenuIcon from './svg/Menu.vue'
import InputField from 'components/InputField.vue'

export default {
  name: 'HeaderComponent',
  components: {
    MenuIcon,
    CloseIcon,
    LoadingIndicator,
    LogoutIcon,
    LoginIcon,
    InputField
  },
  data () {
    return {
      loading: false,
      showError: false,
      loginModal: false,
      menuModal: false,
      forgotPassword: false,
      form: {
        email: '',
        password: ''
      },
      menuLinks: [{
        title: 'О сервисе',
        name: 'about'
      }, {
        title: 'Новости',
        name: 'news'
      }, {
        title: 'Поддержка',
        name: 'support'
      }]
    }
  },
  created () {
    this.loginModal = !this.$store.state.cookies.access_token && this.$route.name === 'account'
  },
  watch: {
    '$route.name' () {
      this.loginModal = !this.$store.state.cookies.access_token && this.$route.name === 'account'
      this.menuModal = false
    }
  },
  methods: {
    submitForm (e) {
      this.forgotPassword ? this.resetPassword(e) : this.login(e)
    },
    resetPassword () {
      if (!this.loading) {
        this.loading = true
        const fd = new FormData()
        fd.append('email', this.form.email)
        this.$store.dispatch('RESET_LINK', fd).then(() => {
          this.$swal('На Вашу почту отправлено письмо с инструкцией по восстановлению пароля!')
          this.forgotPassword = false
          this.loading = false
        }).catch(() => {
          this.$swal('Произошла ошибка! Обратитесь в тех. поддержку')
          this.loading = false
        })
      }
    },
    login () {
      if (!this.loading) {
        this.loading = true
        this.showError = false
        const fd = new FormData()
        fd.append('email', this.form.email)
        fd.append('password', this.form.password)
        this.$store.dispatch('LOGIN', fd).then(({ data }) => {
          // this.$cookie.set('access_token', data.access_token, { expires: data.expires_in, domain: 'sudbot.ru', path: '/', secure: true, sameSite: 'Strict' })
          this.$cookie.set('access_token', data.access_token, {
            expires: moment().add(data.expires_in, 'minutes').toDate().toUTCString(),
            path: '/',
            sameSite: 'Strict'
          })
          this.$store.state.cookies.access_token = data.access_token
          this.$store.dispatch('USER', data.access_token).then(() => {
            this.loginModal = false
            this.showError = false
            this.loading = false
            document.body.classList.remove('no-scroll')
            if (this.$route.name !== 'account') {
              this.$router.push({ name: 'account' })
            } else {
              this.$router.go(0)
            }
          })
        }).catch(({ response }) => {
          if (response && response.status === 401) {
            this.showError = true
          }
          if (response && response.status === 403) {
            this.$swal('Это не админ панель! Вы будете перенаправлены в админку').then(() => {
              location.href = '/administrator'
            })
          }
          this.loading = false
        })
      }
    },
    toggleMenu () {
      this.menuModal = !this.menuModal
    },
    toggleLogin () {
      this.menuModal = false
      if (this.$cookie.get('access_token')) {
        if (this.$route.name === 'account') {
          this.$store.dispatch('LOGOUT', this.$cookie.get('access_token')).then(() => {
            this.$cookie.delete('access_token')
            this.$router.go(0)
          }).catch(({ response }) => {
            if (response.status === 401) {
              this.$cookie.delete('access_token')
              this.$router.go(0)
            }
          })
        } else {
          this.$router.push({ name: 'account' })
        }
      } else {
        this.loginModal = !this.loginModal
        this.showError = false
        this.forgotPassword = false
        if (!this.loginModal) {
          document.body.classList.remove('no-scroll')
          if (this.$route.name === 'account') {
            this.$router.push({ name: 'home' })
          }
        } else {
          document.body.classList.add('no-scroll')
        }
      }
      this.loading = false
    }
  }
}
</script>
