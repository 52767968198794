export default {
  aliment_preview_page (state, data) {
    state.aliment_preview_page = data
  },
  aliment_bot_page (state, data) {
    state.aliment_bot_page = data
  },
  add_aliment_step_form (state, data) {
    state.alimentStepForm = { ...state.alimentStepForm, ...data }
  }
}
