<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 16V17C14 17.7956 13.6839 18.5587 13.1213 19.1213C12.5587 19.6839 11.7956 20 11 20H7C6.20435 20 5.44129 19.6839 4.87868 19.1213C4.31607 18.5587 4 17.7956 4 17V7C4 6.20435 4.31607 5.44129 4.87868 4.87868C5.44129 4.31607 6.20435 4 7 4H11C11.7956 4 12.5587 4.31607 13.1213 4.87868C13.6839 5.44129 14 6.20435 14 7V8M18 16L22 12L18 16ZM22 12L18 8L22 12ZM22 12H8H22Z" stroke="#003B5D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
  </svg>
</template>

<script>
export default {
  name: 'LogoutIcon'
}
</script>
