import axios from 'axios'

/**
 * Получение превью страницы
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_DIVORCE_PREVIEW_PAGE ({ state, commit }) {
  const { data } = await axios.get('/api/previews/divorce')
  commit('divorce_preview_page', data)
}

/**
 * Получение данных страницы оформления развода
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_DIVORCE_BOT_PAGE ({ state, commit }) {
  const { data } = await axios.get('/api/divorcebotpage')
  commit('divorce_bot_page', data)
}

/**
 * Получение данных страницы оформления развода
 * @param state
 * @param commit
 * @param id
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_DIVORCE_CLAIM ({ state, commit }, id) {
  const { data } = await axios.get('/api/getdocumenturl/' + id)
  commit('confirmed_claim', data)
}

/**
 * Добавляет данные выбора судов при отсутствии суда по месту жительства
 * @param commit
 */
function CHOICE_COURTS_DATA ({ commit }, data) {
  commit('choice_courts_data', data)
}

function CHOICE_COURTS_DATA_DELETE ({ commit }) {
  commit('choice_courts_data_delete')
}

function ADD_DIVORCE_STEPFORM ({ commit }, data) {
  commit('add_diverce_step_form', data)
}

/**
 * Добавление цены имущества
 * @param state
 * @param commit
 */
function ADD_PROPERTIES_VALUE ({ state, commit }, data) {
  commit('add_properties_value', data)
}

/**
 * Запрос на получение документа оформления развода
 * @param state
 * @param commit
 * @param data - FormData
 * @returns {Promise<void>}
 * @constructor
 */
async function POST_DIVORCE_BOT ({ state, commit }, data) {
  return await axios.post('/api/divorcebotclaim', data)
}

export {
  GET_DIVORCE_PREVIEW_PAGE,
  GET_DIVORCE_CLAIM,
  GET_DIVORCE_BOT_PAGE,
  POST_DIVORCE_BOT,
  CHOICE_COURTS_DATA,
  ADD_PROPERTIES_VALUE,
  CHOICE_COURTS_DATA_DELETE,
  ADD_DIVORCE_STEPFORM
}
