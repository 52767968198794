import Vue from 'vue'
import moment from 'moment'
import { VueMaskFilter } from 'v-mask'

Vue.filter('formatDate', (date) => {
  return moment(date).format('DD.MM.YYYY')
})

Vue.filter('formatDateRobot', (date) => {
  return moment(date).format('YYYY-MM-DD HH:mm')
})

Vue.filter('vMask', VueMaskFilter)
