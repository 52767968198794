import Vue from 'vue'

export default {
  choice_courts_data (state, { key, value }) {
    Vue.set(state.choiceCourtsData, key, value)
  },
  choice_courts_data_delete (state) {
    state.choiceCourtsData = {}
  },
  divorce_preview_page (state, data) {
    state.divorce_preview_page = data
  },
  divorce_bot_page (state, data) {
    state.divorce_bot_page = data
  },
  add_diverce_step_form (state, data) {
    state.divorceStepForm = { ...state.divorceStepForm, ...data }
  },
  add_properties_value (state, data) {
    state.stepFormProperties = data
  }
}
