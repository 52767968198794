import Vue from 'vue'
import Vuex from 'vuex'
import actions from './actions/index'
import mutations from './mutations/index'

Vue.use(Vuex)
export default function createStore () {
  return new Vuex.Store({
    actions: actions,
    mutations: mutations,
    state () {
      return {
        api: process.env.API || 'http://localhost:8000',
        dadataToken: process.env.DADATA_API_KEY,
        cancelAxios: undefined,
        stepFormProperties: [],
        choiceCourtsData: {},
        alimentStepForm: {},
        divorceStepForm: {}
      }
    },
    getters: {
      getAlimentStepForm: state => {
        return state.alimentStepForm
      },
      getField: state => name => {
        return state.divorce_bot_page.values_bot.find(item => {
          return item.name === name
        })
      },
      getBotPage: state => {
        return state.divorce_bot_page
      }
    }
  })
}
