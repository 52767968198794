import * as home from './home'
import * as about from './about'
import * as article from './article'
import * as cancel from './cancel'
import * as divorce from './divorce'
import * as global from './global'
import * as auth from './auth'
import * as aliment from './aliment'
import * as traffic from './traffic'
import * as support from './support'
import * as decreaseAliment from './decreaseAliment'
import * as increaseAliment from './increaseAliment'

export default Object.assign(
  home,
  about,
  article,
  cancel,
  divorce,
  global,
  auth,
  aliment,
  traffic,
  support,
  decreaseAliment,
  increaseAliment
)
