import axios from 'axios'

axios.defaults.headers.Accept = 'application/json'

/**
 * Логин
 * @param state
 * @param commit
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */
async function LOGIN ({ state, commit }, data) {
  return await axios.post('/api/login', data)
}

/**
 * Получение пользователя
 * @param state
 * @param commit
 * @param token
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */
async function USER ({ state, commit }, token) {
  await axios.post('/api/user', null, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  }).then((res) => {
    if (res.status === 401) {
      commit('user', null)
    } else {
      if (res.data) {
        commit('user', res.data)
      }
    }
  }).catch(({ response }) => {
    if (response && response.status === 401) {
      commit('user', null)
      state.cookies.access_token = undefined
    }
  })
}

/**
 * Деавторизация токена
 * @param state
 * @param commit
 * @param token
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */
async function LOGOUT ({ state, commit }, token) {
  await axios.post('/api/logout', null, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
  commit('user', null)
}

/**
 * Генерация токена сброса пароля и отправка на почту
 * @param state
 * @param commit
 * @param data
 * @param token
 * @returns {Promise<void>}
 * @constructor
 */
async function RESET_LINK ({ state, commit }, data) {
  return await axios.post('/api/resetconfirm', data)
}

/**
 * Генерация токена сброса пароля и отправка на почту
 * @param state
 * @param commit
 * @param data
 * @param token
 * @returns {Promise<void>}
 * @constructor
 */
async function RESET_PASSWORD ({ state, commit }, data) {
  return await axios.post('/api/reset', data)
}

/**
 * Редактирование пользователя
 * @param state
 * @param commit
 * @param token
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */
async function EDIT_USER_NAME ({ state, commit }, { data, token }) {
  await axios.post('/api/editusername', data, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}
/**
 * Редактирование пароля пользователя
 * @param state
 * @param commit
 * @param token
 * @param data
 * @returns {Promise<void>}
 * @constructor
 */
async function EDIT_PASSWORD ({ state, commit }, { data, token }) {
  await axios.post('/api/editpassword', data, {
    headers: {
      Authorization: 'Bearer ' + token
    }
  })
}

export {
  LOGIN,
  USER,
  EDIT_USER_NAME,
  LOGOUT,
  RESET_PASSWORD,
  RESET_LINK,
  EDIT_PASSWORD
}
