import axios from 'axios'

/**
 * Получение данных страницы отмены судебного приказа
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_CANCEL_BOT_PAGE ({ state, commit }) {
  const { data } = await axios.get('/api/cancelbotpage')
  commit('cancel_bot_page', data)
}

/**
 * Получение превью страницы
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_CANCEL_PREVIEW_PAGE ({ state, commit }) {
  const { data } = await axios.get('/api/previews/cancel')
  commit('cancel_preview_page', data)
}

/**
 * Запрос на получение документа отмены судебного приказа
 * @param state
 * @param commit
 * @param data - FormData
 * @returns {Promise<void>}
 * @constructor
 */
async function POST_CANCEL_BOT ({ state, commit }, data) {
  return await axios.post('/api/cancelbotclaim', data)
}

/**
 * Получение судебных участков
 * @param state
 * @param commit
 * @param query - String
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_JUDICAL_SECTOR ({ state, commit }, query) {
  return await axios.get('/api/judicalsectors?q=' + query)
}

/**
 * Получение мировых судов
 * @param state
 * @param commit
 * @param query - String
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_WORLDS_COURTS ({ state, commit }, query) {
  return await axios.get('/api/courts/search?q=' + query + '&type=11')
}

/**
 * Получение районных судов
 * @param state
 * @param commit
 * @param query - String
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_LOCAL_COURTS ({ state, commit }, query) {
  return await axios.get('/api/courts/search?q=' + query + '&type=12')
}

/**
 * Получение подсказок адресов
 * @param state
 * @param commit
 * @param query - String
 * @returns {Promise<void>}
 * @constructor
 */
async function POST_ADDRESS_DADATA ({ state, commit }, query) {
  const CancelToken = axios.CancelToken
  return await axios({
    method: 'post',
    url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
    data: JSON.stringify({ query: query }),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: 'Token ' + state.dadataToken
    },
    cancelToken: new CancelToken((c) => {
      // An executor function receives a cancel function as a parameter
      state.cancelAxios = c
    }),
    mode: 'cors'
  })
}

export {
  GET_CANCEL_BOT_PAGE,
  GET_JUDICAL_SECTOR,
  GET_WORLDS_COURTS,
  GET_LOCAL_COURTS,
  POST_CANCEL_BOT,
  POST_ADDRESS_DADATA,
  GET_CANCEL_PREVIEW_PAGE
}
