import aliment from './aliment'
import divorce from './diverce'
import home from './home'
import cancel from './cancel'
import traffic from './traffic'
import otherPage from './otherPage'
import decreaseAliment from './decreaseAliment'
import increaseAliment from './increaseAliment'

export default Object.assign(
  home,
  otherPage,
  divorce,
  aliment,
  cancel,
  traffic,
  decreaseAliment,
  increaseAliment
)
