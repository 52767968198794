import axios from 'axios'

/**
 * Получение превью страницы
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_INCREASE_ALIMENT_PREVIEW_PAGE ({ state, commit }) {
  const { data } = await axios.get('/api/previews/alimony_increase')
  commit('increase_aliment_preview_page', data)
}

export {
  GET_INCREASE_ALIMENT_PREVIEW_PAGE
}
