import axios from 'axios'

/**
 * Получение превью страницы
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_ALIMENT_PREVIEW_PAGE ({ state, commit }) {
  const { data } = await axios.get('/api/previews/alimony')
  commit('aliment_preview_page', data)
}

/**
 * Получение данных страницы оформления алиментов
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_ALIMENT_BOT_PAGE ({ state, commit }) {
  const { data } = await axios.get('/api/alimony')
  commit('aliment_bot_page', data)
}

/**
 * Запрос на получение документа оформления алиментов
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function POST_ALIMENT_BOT ({ state, commit }, data) {
  return await axios.post('/api/alimony', data)
}

/**
 * Запрос на получение документа оформления алиментов
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function ADD_ALIMENT_STEPFORM ({ commit }, data) {
  commit('add_aliment_step_form', data)
}

export {
  GET_ALIMENT_PREVIEW_PAGE,
  GET_ALIMENT_BOT_PAGE,
  POST_ALIMENT_BOT,
  ADD_ALIMENT_STEPFORM
}
