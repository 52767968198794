import Vue from 'vue'
import 'es6-promise/auto'
import createApp from './app'

Vue.mixin({
  beforeRouteUpdate (to, from, next) {
    const { asyncData } = this.$options
    if (asyncData) {
      asyncData({
        store: this.$store,
        route: to
      }).then(next).catch(next)
    } else {
      next()
    }
  }
})

const { app, router, store } = createApp()
const { location } = window

if (window.__INITIAL_STATE__) {
  store.replaceState(window.__INITIAL_STATE__)
}

router.onReady(() => {
  router.beforeResolve((to, from, next) => {
    const matched = router.getMatchedComponents(to)
    const prevMatched = router.getMatchedComponents(from)
    let diffed = false
    const activated = matched.filter((c, i) => {
      diffed = diffed || prevMatched[i] !== c
      return diffed
    })
    const asyncDataHooks = activated.map(c => c.asyncData).filter(_ => _)

    if (!asyncDataHooks.length) {
      return next()
    }
    return Promise.all(asyncDataHooks.map(hook => hook({ store, route: to }))).then(() => {
      next()
    }).catch(next)
  })
  app.$mount('#sudbot')
})

router.afterEach((to, from) => {
  if (to.name === 'divorce-start') {
    const sednPuls = document.createElement('script')
    sednPuls.setAttribute(
      'sp-form-id', '1c9b6eacd62f7f48a4ebf4f748b76bda3fe5d2c1a8ea34ec5f91689f03cb4326'
    )
    sednPuls.setAttribute(
      'src', '//web.webformscr.com/apps/fc3/build/loader.js'
    )
    document.head.appendChild(sednPuls)
  } else if (to.name === 'aliment-start') {
    const sednPuls = document.createElement('script')
    sednPuls.setAttribute(
      'sp-form-id', 'e8df9b6435f7e8be73195377f0425e527a2505522c3c0b4035a081b649a59378'
    )
    sednPuls.setAttribute(
      'src', '//web.webformscr.com/apps/fc3/build/loader.js'
    )
    document.head.appendChild(sednPuls)
  } else if (to.name === 'increase-aliment-start') {
    const sednPuls = document.createElement('script')
    sednPuls.setAttribute(
      'sp-form-id', '0133ee71faeb6c6f74b23560b5ad6fc5d5aca61e765a353b8e29ab50fcd5d292'
    )
    sednPuls.setAttribute(
      'src', '//web.webformscr.com/apps/fc3/build/loader.js'
    )
    document.head.appendChild(sednPuls)
  } else if (to.name === 'decrease-aliment-start') {
    const sednPuls = document.createElement('script')
    sednPuls.setAttribute(
      'sp-form-id', '328ed50aad44527e07d1071e7bb3142007d0ea8cc65e4e3470556adfaadfe7a4'
    )
    sednPuls.setAttribute(
      'src', '//web.webformscr.com/apps/fc3/build/loader.js'
    )
    document.head.appendChild(sednPuls)
  } else if (to.name === 'traffic-start') {
    const sednPuls = document.createElement('script')
    sednPuls.setAttribute(
      'sp-form-id', '83e75666f522b08c2db3ec0db1954be8b9ecdbef7df24f01b5f6fbf8b9defb2c'
    )
    sednPuls.setAttribute(
      'src', '//web.webformscr.com/apps/fc3/build/loader.js'
    )
    document.head.appendChild(sednPuls)
  } else if (to.name === 'cancel-start') {
    const sednPuls = document.createElement('script')
    sednPuls.setAttribute(
      'sp-form-id', '9c2e019decfa95910320cebfbf241c7fcf0d31eef928c633a5b70ec99ca49b95'
    )
    sednPuls.setAttribute(
      'src', '//web.webformscr.com/apps/fc3/build/loader.js'
    )
    document.head.appendChild(sednPuls)
  }
})

if (location.protocol === 'https:' && navigator.serviceWorker) {
  navigator.serviceWorker.register('/service-worker.js')
}
