import axios from 'axios'

/**
 * Получение данных страницы поддержки
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_SUPPORT_PAGE ({ state, commit }) {
  const { data } = await axios.get('/api/supportpage')
  commit('support_page', data)
}

export {
  GET_SUPPORT_PAGE
}
