import axios from 'axios'

/**
 * Получение данных страницы статьи
 * @param state
 * @param commit
 * @param id - Integer
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_ARTICLE_PAGE ({ state, commit }, id) {
  const { data } = await axios.get('/api/article/' + id)
  commit('article_page', data)
}

/**
 * Получение данных страницы списка статей
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_ARTICLES ({ state, commit }) {
  const { data } = await axios.get('/api/articles')
  commit('articles_page', data)
}

export {
  GET_ARTICLES,
  GET_ARTICLE_PAGE
}
