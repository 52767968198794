import axios from 'axios'

/**
 * Получение превью страницы
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_TRAFFIC_PREVIEW_PAGE ({ state, commit }) {
  const { data } = await axios.get('/api/previews/traffic')
  commit('traffic_preview_page', data)
}

export {
  GET_TRAFFIC_PREVIEW_PAGE
}
