export default {
  home_page (state, data) {
    state.home_page = data
  },
  about_page (state, data) {
    state.about_page = data
  },
  support_page (state, data) {
    state.support_page = data
  },
  articles_page (state, data) {
    state.articles_page = data
  },
  article_page (state, data) {
    state.article_page = data
  }
}
