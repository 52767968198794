<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0)">
      <path d="M4.92871 4.92896L19.0708 19.0711" stroke="#003366" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
      <path d="M4.92871 19.071L19.0708 4.92891" stroke="#003366" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="24" height="24" fill="white"></rect>
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'CloseIcon'
}
</script>
