<template>
  <footer class="footer"
          :class="{
            'footer--white': $route.name === 'home'
          }">
    <div class="footer__container">
      <div class="footer__socials">
        <span class="footer__socials-title">мы в соц.сетях</span>
        <div class="footer__socials-list">
          <a class="footer__socials-icon" href="https://www.youtube.com/watch?v=Bx_yNj9qi7k&list=PLoeU5d3RWH6rIZ7Qnfvpa1A1kx18hS7-F" target="_blank" rel="nofollow">
            <YouTubeIcon/>
          </a>
          <a class="footer__socials-icon footer__socials-icon--telegram" href="https://t.me/sudbotrus" target="_blank" rel="nofollow">
            <TelegramIcon/>
          </a>
        </div>
      </div>
      <router-link :to="{ name: 'home' }" class="footer__copy" title="sudbot.ru" aria-label="sudbot.ru">{{ year }} © sudbot.ru</router-link>
      <router-link :to="{ name: 'confidentiality' }" title="Политика конфиденциальности" aria-label="Политика конфиденциальности">Политика конфиденциальности</router-link>
    </div>
  </footer>
</template>

<script>
import moment from 'moment'
import YouTubeIcon from './svg/YoutubeIcon.vue'
import TelegramIcon from './svg/TelegramIcon.vue'

export default {
  name: 'FooterComponent',
  components: { YouTubeIcon, TelegramIcon },
  data () {
    const year = moment().format('YYYY')
    return {
      year: year
    }
  }
}
</script>
