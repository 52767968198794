<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path opacity="0.9" fill-rule="evenodd" clip-rule="evenodd" d="M20.9059 4.15427C21.5644 4.82307 21.7793 6.33887 21.7793 6.33887C21.7793 6.33887 22 7.8202 22 9.60073V11.8015C22 13.5813 21.7793 15.1293 21.7793 15.1293C21.7793 15.1293 21.5644 16.6451 20.9059 17.3125C20.1516 18.1024 19.3151 18.1839 18.8427 18.2298C18.7911 18.2349 18.7438 18.2395 18.7015 18.2445C15.6222 18.4667 11 18.4007 11 18.4007C11 18.4007 5.28 18.422 3.52 18.2533C3.43651 18.2377 3.33532 18.2255 3.22074 18.2118C2.66305 18.1447 1.78826 18.0394 1.09413 17.3125C0.434133 16.6444 0.22 15.1293 0.22 15.1293C0.22 15.1293 0 13.5805 0 11.8007V9.6C0 7.8202 0.22 6.33887 0.22 6.33887C0.22 6.33887 0.434133 4.82307 1.09413 4.155C1.8484 3.36503 2.68491 3.2836 3.15725 3.23762C3.20889 3.2326 3.25618 3.22799 3.29853 3.22293C6.37707 3 11 3 11 3C11 3 15.6222 3 18.7015 3.2222C18.7444 3.2274 18.7923 3.23212 18.8447 3.23728C19.3174 3.28382 20.1527 3.36607 20.9059 4.15427ZM8.94666 7.77179V13.6385L14.8133 10.7051L8.94666 7.77179Z" fill="#FF0302"></path>
  </svg>
</template>

<script>
export default {
  name: 'YouTubeIcon'
}
</script>
