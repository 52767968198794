import axios from 'axios'

/**
 * Получение данных страницы о нас
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function GET_ABOUT_PAGE ({ state, commit }) {
  const { data } = await axios.get('/api/aboutpage')
  commit('about_page', data)
}

export {
  GET_ABOUT_PAGE
}
