import Vue from 'vue'
import { sync } from 'vuex-router-sync'
import * as filters from './filters.js'

import Meta from 'vue-meta'
import Cookie from 'vue-cookie'
import VueSwal from 'vue-swal'
import Seo from './seo.js'
import VueProgressBar from 'vue-progressbar'
import vSelect from 'vue-select'
import { VueMaskDirective } from 'v-mask'
import VTooltip from 'v-tooltip'
import VueMq from 'vue-mq'

import App from './App.vue'
import createStore from './store'
import createRouter from './router.js'

// Validation
import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';
import ru from 'vee-validate/dist/locale/ru.json';
import * as rules from 'vee-validate/dist/rules.umd';
import axios from 'axios'

// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

localize('ru', ru);

// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

Vue.use(VueProgressBar, {
  color: '#255789',
  failedColor: '#d9000d',
  thickness: '6px',
  transition: {
    speed: '0.2s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: true,
  location: 'top',
  inverse: false
})
Vue.use(Meta)
Vue.use(Cookie)
Vue.use(VueSwal)
Vue.use(Seo)
Vue.use(VTooltip, {
  defaultPlacement: 'top',
  autoHide: false
})
Vue.use(VueMq, {
  breakpoints: {
    700: 768,
    lg: Infinity
  },
  defaultBreakpoint: '700'
})
Vue.component('VSelect', vSelect)
Vue.directive('mask', VueMaskDirective)

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
const isProd = process.env.NODE_ENV === 'production'
axios.defaults.baseURL = isProd ? 'https://api.sudbot.ru' : process.browser ? 'http://localhost:9000' : 'http://app:9000'

export default function createApp () {
  const store = createStore()
  const router = createRouter()
  sync(store, router)

  const app = new Vue({
    router,
    store,
    render: h => h(App)
  })

  return { app, router, store }
}
