<template>
  <div id="sudbot" :class="{'transparent': $route.meta.transparentHeader}">
    <vue-progress-bar/>
    <HeaderComponent/>
    <Transition name="fade" mode="out-in">
      <RouterView :key="$route.fullPath"/>
    </Transition>
    <FooterComponent/>
  </div>
</template>

<script>
import HeaderComponent from './components/Header.vue'
import FooterComponent from './components/Footer.vue'

export default {
  components: { FooterComponent, HeaderComponent },
  metaInfo () {
    return this.$seo()
  },
  created () {
    this.$Progress.start()
    this.$router.beforeEach((to, from, next) => {
      if (from.meta.transparentHeader) {
        this.$Progress.setColor('#ffffff')
      } else {
        this.$Progress.setColor('#005c9c')
      }
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress
        this.$Progress.parseMeta(meta)
      }
      this.$Progress.start()
      next()
    })
    this.$router.afterEach(() => {
      this.$Progress.finish()
    })
  },
  mounted () {
    this.$Progress.finish()
  }
}
</script>

<style lang="scss">
@import "styles/app.scss";
</style>
