import axios from 'axios'

/**
 * Получение токенов ларавел
 * @param state
 * @param commit
 * @returns {Promise<void>}
 * @constructor
 */
async function SETUP_TOKEN ({ state, commit }) {
  return await axios.get('/sanctum/csrf-cookie')
}

export {
  SETUP_TOKEN
}
